import base from '../layout';

export default {
  ...base,
  badge: {
    ...base.badge,
    schemes: {
      ...base.badge.schemes,
      badgeText: 'alternative',
    },
    colors: {
      badgeBg: 'highlight',
    },
  },
};
